import { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Input, Label } from "reactstrap";
import React from "react";
import { useIntl } from "react-intl";

const PasswordInput = ({
  errors,
  register,
  field = "password",
  labelId = "auth.login.password",
  placeholderId = "auth.login.password",
}) => {
  const [, setPassword] = useState("");
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(FiEyeOff);
  const intl = useIntl();

  const handleToggle = () => {
    if (type === "password") {
      setIcon(FiEye);
      setType("text");
    } else {
      setIcon(FiEyeOff);
      setType("password");
    }
  };

  return (
    <div>
      <div>
        <div className={"password-input-group"}>
          <Label for={field} className={"label-required"}>
            {intl.formatMessage({
              id: labelId,
            })}
          </Label>
          <div className={"password-input"}>
            <Input
              type={type}
              name={field}
              id={field}
              placeholder={intl.formatMessage({
                id: placeholderId,
              })}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
              innerRef={register}
            />
            <span className={"password-show-hide"} onClick={handleToggle}>
              {icon}
            </span>
          </div>
          {errors[field] && (
            <span className="text-danger">{errors[field].message}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default PasswordInput;
